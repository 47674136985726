import { combineReducers } from 'redux';
import NavigationReducer from './NavigationReducer';
import UserReducer from './UserReducer';
import ClinicReducer from './ClinicReducer';

const RootReducer = combineReducers({
  navigations: NavigationReducer,
  users: UserReducer,
  clinics: ClinicReducer,
});

export default RootReducer;
