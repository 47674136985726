import { toast } from 'react-toastify';
import axios from '../../../axios.js';

export const ADD_CLINIC = 'ADD_CLINIC';
export const GET_CLINICS = 'GET_CLINIC';
export const GET_CLINIC_BY_ID = 'GET_CLINIC_BY_ID';
export const CLINIC_DELETE = 'CLINIC_DELETE';
export const CHANGE_STATUS = 'CHANGE_STATUS';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';

export const addClinic = (clinicName, username, password, createdBy) => async (dispatch) => {
  try {
    const response = await axios.post('/clinic', { clinicName, username, password, createdBy });
    dispatch({
      type: ADD_CLINIC,
      payload: response.data,
    });
    toast.success(response.data.message);
  } catch (err) {
    toast.error(err.message);
  }
};

export const getClinics = () => async (dispatch) => {
  try {
    const response = await axios.get('/clinic');
    dispatch({
      type: GET_CLINICS,
      payload: response.data,
    });
  } catch (err) {
    toast.error(err.message);
  }
};

export const deleteClinic = (id) => async (dispatch) => {
  try {
    const response = await axios.delete(`/clinic/${id}`);
    dispatch({
      type: CLINIC_DELETE,
      payload: response.data.results,
    });
    toast.success(response.data.message);
  } catch (err) {
    toast.error(err.message);
  }
};

export const statusChange = (id, status) => async (dispatch) => {
  try {
    const response = await axios.put('/clinic/status/edit', { id, status });
    dispatch({
      type: CHANGE_STATUS,
      payload: response.data.results,
    });
    toast.success(response.data.message);
  } catch (err) {
    toast.error(err.message);
  }
};

export const passwordChange = (id, password) => async (dispatch) => {
  try {
    const response = await axios.put('/clinic/password/edit', { id, password });
    dispatch({
      type: CHANGE_PASSWORD,
      payload: response.data.results,
    });
    toast.success(response.data.message);
  } catch (err) {
    toast.error(err.message);
  }
};

export const getClinicById = (id) => async (dispatch) => {
  try {
    const response = await axios.get(`/clinic/${id}`);
    dispatch({
      type: GET_CLINIC_BY_ID,
      payload: response.data,
    });
  } catch (err) {
    toast.error(err.message);
  }
};
