import {
  ADD_USER,
  DELETE_USER,
  GET_USER_LIST,
  UPDATE_USER,
  UPDATE_STATUS,
  UPDATE_PASSWORD,
} from '../actions/UserActions';

const initialState = {
  userList: [],
};

const UserReducer = function (state = initialState, action) {
  switch (action.type) {
    case ADD_USER: {
      return {
        ...state,
        userList: [...action.payload],
      };
    }
    case GET_USER_LIST: {
      return {
        ...state,
        userList: [...action.payload],
      };
    }
    case DELETE_USER: {
      return {
        ...state,
        userList: [...state.userList.filter((d) => d.id !== action.payload)],
      };
    }
    case UPDATE_USER: {
      return {
        ...state,
        userList: [...action.payload],
      };
    }
    case UPDATE_STATUS: {
      return {
        ...state,
        userList: [...action.payload],
      };
    }
    case UPDATE_PASSWORD: {
      return {
        ...state,
        userList: [...action.payload],
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export default UserReducer;
