import { toast } from 'react-toastify';
import axios from '../../../axios';

export const ADD_USER = 'ADD_USER';
export const GET_USER_LIST = 'GET_USER_LIST';
export const UPDATE_USER = 'UPDATE_USER';
export const DELETE_USER = 'DELETE_USER';
export const UPDATE_STATUS = 'UPDATE_STATUS';
export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';

export const getUsersList = () => async (dispatch) => {
  try {
    const response = await axios.get('/user');
    dispatch({
      type: GET_USER_LIST,
      payload: response.data,
    });
  } catch (err) {
    toast.error(err.message);
  }
};

export const addUser = (name, username, password, role) => async (dispatch) => {
  try {
    const response = await axios.post('/user', { name, username, password, role });
    dispatch({
      type: ADD_USER,
      payload: response.data.results,
    });
    toast.success(response.data.message);
  } catch (err) {
    toast.error(err.message);
  }
};

export const deleteUser = (id) => async (dispatch) => {
  try {
    const response = await axios.delete(`/user/${id}`);
    dispatch({
      type: DELETE_USER,
      payload: id,
    });
    toast.success(response.data.message);
  } catch (err) {
    toast.error(err.message);
  }
};

export const updateUser = (id, name, username, role) => async (dispatch) => {
  try {
    const response = await axios.put('/user', { id, name, username, role });
    dispatch({
      type: UPDATE_USER,
      payload: response.data.results,
    });
    toast.success(response.data.message);
  } catch (err) {
    toast.error(err.message);
  }
};

export const updateStatus = (id, status) => async (dispatch) => {
  try {
    const response = await axios.put('/user/status/edit', { id, status });
    dispatch({
      type: UPDATE_STATUS,
      payload: response.data.results,
    });
    toast.success(response.data.message);
  } catch (err) {
    toast.error(err.message);
  }
};

export const updatePassword = (id, password) => async (dispatch) => {
  try {
    const response = await axios.put('/user/password/edit', { id, password });
    dispatch({
      type: UPDATE_PASSWORD,
      payload: response.data.results,
    });
    toast.success(response.data.message);
  } catch (err) {
    toast.error(err.message);
  }
};
