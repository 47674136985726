import { authRoles } from './auth/authRoles';

export const navigations = [
  {
    name: 'Dashboard',
    path: '/dashboard/default',
    icon: 'dashboard',
    auth: authRoles.receptionist,
  },
  {
    name: 'Clinics',
    path: '/clinics',
    icon: 'local_hospital',
    auth: authRoles.admin,
  },
  {
    name: 'Users',
    path: '/users',
    icon: 'group',
    auth: authRoles.admin,
  },
];
