import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const Dashboard = Loadable(lazy(() => import('./Dashboard')));

const dashboardRoutes = [
  { path: '/dashboard/default', element: <Dashboard />, auth: authRoles.receptionist },
];

export default dashboardRoutes;
