import {
  ADD_CLINIC,
  CHANGE_PASSWORD,
  CHANGE_STATUS,
  CLINIC_DELETE,
  GET_CLINICS,
  GET_CLINIC_BY_ID,
} from '../actions/ClinicActions';

const initialState = {
  clinicList: [],
  clinic: null,
};

const ClinicReducer = function (state = initialState, action) {
  switch (action.type) {
    case ADD_CLINIC: {
      return {
        ...state,
        clinicList: [...state.clinicList],
      };
    }
    case GET_CLINICS: {
      return {
        ...state,
        clinicList: [...action.payload],
      };
    }
    case CLINIC_DELETE: {
      return {
        ...state,
        clinicList: [...action.payload],
      };
    }
    case CHANGE_STATUS: {
      return {
        ...state,
        clinicList: [...action.payload],
      };
    }
    case CHANGE_PASSWORD: {
      return {
        ...state,
        clinicList: [...action.payload],
      };
    }
    case GET_CLINIC_BY_ID: {
      return {
        ...state,
        clinic: action.payload,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export default ClinicReducer;
