import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const Clinics = Loadable(lazy(() => import('./Clinic')));
const AddClinic = Loadable(lazy(() => import('./AddClinic')));
const EditClinic = Loadable(lazy(() => import('./EditClinic')));

const clinicRoutes = [
  { path: '/clinics', element: <Clinics />, auth: authRoles.admin },
  { path: '/clinic/add', element: <AddClinic />, auth: authRoles.admin },
  { path: '/clinic/edit/:id', element: <EditClinic />, auth: authRoles.admin },
];

export default clinicRoutes;
